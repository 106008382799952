// const apiUrl = 'http://85.113.18.173:8000';
// const apiUrl = 'http://5.180.138.126:8080';
//force ci build - ignore this
// const apiUrl = 'http://localhost:8000';
// const apiUrl = process.env.NODE_ENV === 'development' ? 'https://mes-mobile.yorc.org' : '';
// const apiUrl = 'https://mes-mobile.yorc.org';
// const apiUrl = 'https://new.mes.yorc.org';

const origin = window.location.origin.includes('localhost') ? 'https://mes-mobile.yorc.org' : window.location.origin;
const apiUrl = origin;

const config = {
  apiUrl: apiUrl + '/api',
  uploadsUrl: apiUrl + '/upload/',
  atlasUrl: 'https://atlas.yorc.ru',
  nominatimOpenstreetmap: 'https://nominatim.openstreetmap.org'
};

export default config;
